import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/messaging'
import _ from 'lodash'
import moment from 'moment'
import config from '../../config'
import { historyPush, moveTopPage } from '../../containers/App/operations'
import { initializeFirebase } from '../../services/firebase'
import { DeviceRaw, Log, LogRaw, ThunkAction } from '../../types'
import { apiClient, toLog } from '../../utils'
import { decrypt, encrypt } from '../../utils/encrypt'
import * as authActions from '../Auth/actions'
import { saveLogin } from '../Auth/operations'
import { getLoginUser, getLoginUserDeviceIds } from '../Auth/selectors'
import { updateDevice } from '../DeviceById/actions'
import { saveDevice } from '../DeviceById/operations'
import { setLoginErrorMessage } from '../LoginForm/actions'
import { editUserEnd } from '../SystemCache/actions'
import { receiveUser } from '../UserById/actions'

const messageLoginError = setLoginErrorMessage(
  '認証できませんでした。ご登録いただいているメールアドレスとパスワードを入力してください。',
)

initializeFirebase()
const fdb = firebase.database()

async function convertLogin(
  email: string,
  password: string,
): Promise<string | undefined> {
  const res = await apiClient
    .post<{ password: string }>('convert_login', {
      email,
      password,
    })
    .catch(() => {
      return undefined
    })

  return res && res.data.password
}

export function passwordLogin(email: string, password: string) {
  return async dispatch => {
    const proPassword = await convertLogin(email, encrypt(password))

    console.log('pp', proPassword)
    if (!proPassword) {
      dispatch(messageLoginError)
      return
    }
    console.log('auth')

    const res = await firebase
      .auth()
      .signInWithEmailAndPassword(email, decrypt(proPassword))
      .catch(e => {
        console.error(e)
        if (e.code === 'auth/wrong-password') {
          dispatch(messageLoginError)
        }
        return { user: null }
      })

    if (res.user) {
      console.log(res.user)
      console.log(res.user.uid)
      await dispatch(authStateChanged(res.user?.uid || null))
    }
  }
}

export function logout(nextPath?: string): ThunkAction {
  return async dispatch => {
    await firebase
      .auth()
      .signOut()
      .catch(console.error)
    await dispatch(authActions.logout())
    historyPush(nextPath || '/login')
  }
}

function authStateChanged(uid: string | null, redirect = true) {
  return async dispatch => {
    if (!uid) {
      console.log({ uid })
      return dispatch(authActions.loginFailed())
    }
    const userRef = fdb.ref(`user/${uid}`)
    const snapshot = await userRef.once('value')

    if (snapshot.exists()) {
      const user = snapshot.val()

      await dispatch(saveLogin(user))
      if (redirect) {
        moveTopPage()
      }
      return
    }
    dispatch(messageLoginError)
    dispatch(authActions.loginFailed())
  }
}

export function refInit(): ThunkAction {
  return async dispatch => {
    firebase.auth().onAuthStateChanged(user => {
      dispatch(authStateChanged(user?.uid || null, false))
    })
  }
}

const userSnapshotChanged = snapshot => dispatch => {
  if (!snapshot) {
    return
  }
  const user = snapshot.val()

  if (!user) {
    return
  }
  dispatch(receiveUser(user))
}

export function initialFirebaseLoad(): ThunkAction {
  return async dispatch => {
    dispatch(syncUserData())
    dispatch(loadDevices())
  }
}

export function syncUserData(): ThunkAction {
  return async dispatch => {
    fdb
      .ref('user')
      .orderByChild('deleted')
      .equalTo(false)
      .on('value', snapshot => {
        if (!snapshot) {
          return
        }
        const users = snapshot.val()

        _.map(users, user => {
          dispatch(receiveUser(user))
        })
      })
  }
}

export function syncLoginUserData(): ThunkAction {
  return async (dispatch, getState) => {
    const userState = getLoginUser(getState())

    fdb.ref(`user/${userState.id}`).on('value', snapshot => {
      dispatch(userSnapshotChanged(snapshot))
    })
  }
}

function loadDevice(deviceId: string): ThunkAction {
  return async dispatch => {
    const { deviceRef, sessionRef } = await getSessionRefs(deviceId)
    const device = (await deviceRef.once('value')).val() as DeviceRaw

    if (device.category !== config.categoryKey) return

    const lastLogSnap = await sessionRef.child('lastLog').once('value')
    const logRaw: LogRaw | undefined = lastLogSnap.val()

    dispatch(
      saveDevice({
        ..._.pick(device, [
          'data',
          'info',
          'updatedAt',
          'currentUserId',
          'currentSessionId',
        ]),
        selector: `${deviceId}__${device.currentSessionId}`,
        id: deviceId,
        lastLogs: logRaw ? { [device.currentSessionId]: toLog(logRaw) } : {},
      }),
    )
    deviceRef.on('value', snapshot => {
      if (!snapshot) {
        return
      }
      const device = snapshot.val()

      dispatch(
        updateDevice({
          id: deviceId,
          data: device.data,
          info: device.info,
          updatedAt: device.updatedAt,
        }),
      )
    })

    fdb.ref(`device/${deviceId}/currentSessionId`).on('value', snap => {
      if (snap.val() !== device.currentSessionId) {
        dispatch(loadDevice(deviceId))
      }
    })
  }
}

export function loadDevices(): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(syncLoginUserData())
    const deviceIds = getLoginUserDeviceIds(getState())

    deviceIds.forEach(id => dispatch(loadDevice(id)))
  }
}

export async function getSessionRefs(deviceId: string) {
  const deviceRef = fdb.ref(`device/${deviceId}`)
  const deviceRaw: DeviceRaw = (await deviceRef.once('value')).val()
  const { currentUserId, currentSessionId } = deviceRaw
  const sessionRef = fdb.ref(
    `user-device-session-log/${currentUserId}/${deviceId}/${currentSessionId}`,
  )

  return { deviceRef, deviceRaw, sessionRef }
}

const dayEndsUnix = (day: string) => {
  const m = moment(day, 'YYYY-MM-DD')
  const dayStart = m.startOf('day').unix() * 1000
  const dayEnd = m.endOf('day').unix() * 1000

  return { dayStart, dayEnd }
}

export async function loadLogs(deviceId: string, day: string): Promise<Log[]> {
  const { dayStart, dayEnd } = dayEndsUnix(day)
  const { sessionRef } = await getSessionRefs(deviceId)

  const deviceLogRef = sessionRef.child('logs')

  // sessionRef.child('info').on('value', () => {})
  const logsSnap = await deviceLogRef
    .orderByChild('timestamp')
    .startAt(dayStart)
    .endAt(dayEnd)
    .once('value')

  const logRaws: { [key: string]: LogRaw } = logsSnap.val()
  const logs: Log[] = _.map(logRaws, (log, key) => toLog(log, key))

  return logs
}

export function updateUserTitle(userId: string, title: string) {
  return () => {
    fdb.ref(`user/${userId}/title`).set(title)
  }
}

export async function updateDeviceCurrentUser(
  deviceId: string,
  newCurrentUserId: string,
) {
  const deviceRef = fdb.ref(`device/${deviceId}`)
  const device = (await deviceRef.once('value')).val()

  if (newCurrentUserId === device.currentUserId) {
    return
  }
  await fdb.ref(`user/${device.currentUserId}/deviceIds/${deviceId}`).set(null)
  await fdb.ref(`user/${newCurrentUserId}/deviceIds/${deviceId}`).set(true)
  await deviceRef.update({
    currentUserId: newCurrentUserId,
    currentSessionId: Number(device.currentSessionId) + 1,
  })
}

export function updateDeviceInfo(
  deviceId: string,
  fields: { [key: string]: any },
) {
  return () => {
    fdb
      .ref(`device/${deviceId}/info`)
      .update(
        _.pick(fields, ['status', 'priority', 'label', 'visible', 'memo']),
      )
    if (fields.currentUserId) {
      updateDeviceCurrentUser(deviceId, fields.currentUserId)
    }
  }
}

export function toggleDeviceVisible(deviceId: string, visible: boolean) {
  fdb.ref(`device/${deviceId}/info`).update({ visible })
}

export function updateUser(
  id: string,
  fields: { label: string; memo: string },
) {
  return async dispatch => {
    fdb.ref(`user/${id}`).update(fields)

    dispatch(editUserEnd())
  }
}

export async function fetchLogs(
  deviceId: string,
  startAt: number,
  endAt: number,
  receiveLogs: (logs: LogRaw[]) => void,
  watch = false,
) {
  const { sessionRef } = await getSessionRefs(deviceId)

  console.log('load start, ', startAt, endAt)
  const logsRef = sessionRef
    .child('logsRoadcell')
    .orderByChild('timestamp')
    .limitToLast(config.loadLogCountLimit)

  await logsRef
    .startAt(startAt)
    .endAt(endAt)
    .once('value', receiveSnapshot)
  const unsubscribe = watch
    ? logsRef.startAt(endAt).on('child_added', receiveSnapshot)
    : () => {
        //
      }

  function receiveSnapshot(snap: firebase.database.DataSnapshot) {
    const log = snap?.val()

    if (!log) return receiveLogs([])

    if (log.timestamp) {
      receiveLogs([log])
    } else {
      receiveLogs(Object.values(log))
    }
    // return [Object.values(logs), unit]
  }
  const unit = (await sessionRef.child('units/ch01').once('value')).val() || '-'

  return { unsubscribe, unit }
}
