import _ from 'lodash'
import { Device, State } from '../../types'
import { getLoginUserDeviceIds } from '../Auth/selectors'
import { getUser } from '../UserById/selectors'

export const getDevice = (state: State, deviceId: string) =>
  state.DeviceById[deviceId]
export const getDeviceIds = (state: State) => _.keys(state.DeviceById)

export const getVisibleDeviceIds = (state: State, visible: boolean = true) => {
  const devices = getDevices(state)
  const list = _.sortBy(
    visible ? devices.filter(v => v.info.visible) : devices,
    [v => v.info.priority, v => v.info.label],
  )

  return list.map(v => v.id)
}

export const getVisibleDeviceMap = (state: State) => {
  const devices = getDevices(state)
  const visibles: Record<string, boolean> = {}

  devices.forEach(d => {
    visibles[d.id] = d.info.visible
  })
  return visibles
}

export const getDevices = (state: State) => {
  const deviceIds = getLoginUserDeviceIds(state)

  return _.filter(
    deviceIds.map(did => getAppDevice(state, did)),
    v => !!v,
  ) as Device[]
}

export const getCurrentUser = (state: State, deviceId: string) => {
  const device = getDevice(state, deviceId)

  return getUser(state, device.currentUserId)
}

export const getAppDevice = (state: State, deviceId: string) => {
  const device = getDevice(state, deviceId)

  if (!device) {
    return undefined
  }
  // const now = getTimestamp(state)

  return device
}
