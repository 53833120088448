import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { CircularProgress } from '@material-ui/core'
import { getAuth } from '../../state/Auth/selectors'
import { Auth, State } from '../../types'

type OProps = {
  redirectPath: string
}

type Props = {
  auth: Auth
  redirectPath: string
  userRoute: React.ReactNode
}

const AuthComponent = (props: Props) => {
  const { auth } = props

  if (auth.loading) {
    return (
      <div style={{ margin: '1em' }}>
        <CircularProgress color="secondary" />
      </div>
    )
  }
  if (!auth.authorized || auth.isAdmin) {
    return <Redirect to={props.redirectPath} />
  }
  return <>{props.userRoute}</>
}

const ms = (state: State, op: OProps) => ({
  auth: getAuth(state),
})

const conn = connect(ms, {})

const AuthContainer = conn(AuthComponent)

export default AuthContainer
