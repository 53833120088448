import { TextField, Typography, Button } from '@material-ui/core'
import * as React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FieldError, LoginField } from '../../../types'

type Fields = LoginField
export type Props = {
  error: FieldError
  onSubmit: (fields: Fields) => void
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('emailの形式が正しくありません')
    .required('必須項目です'),
  password: Yup.string()
    .min(8, '8文字以上入力してください')
    .max(50, 'パスワードが長すぎます')
    .required('必須項目です'),
})

const LoginForm = (props: Props) => {
  const formik = useFormik<Fields>({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: values => props.onSubmit(values),
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: loginSchema,
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        maxWidth: '400px',
        flexDirection: 'column',
      }}
    >
      <TextField
        name="email"
        label="メールアドレス"
        value={formik.values.email}
        onChange={formik.handleChange}
        type="text"
        autoComplete="email"
        margin="normal"
        required
      />
      <TextField
        name="password"
        label="パスワード"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        autoComplete="current-password"
        margin="normal"
        required
      />
      {props.error.hasError && (
        <Typography color="error">{props.error.message}</Typography>
      )}
      <Button type="submit" variant="outlined" color="primary">
        ログイン
      </Button>
    </form>
  )
}

export default LoginForm
