import { Button } from '@material-ui/core'
import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Admin, Auth, User } from '../../../types'

export type Props = {
  user: User | Admin
  auth: Auth
  title?: string
  onClickTitle: () => void
  onClickLogout: (nextPath?: string) => void
}

const NavBar = (props: Props) => (
  <div>
    <AppBar position="static" color="secondary">
      <Toolbar>
        <Typography
          onClick={props.onClickTitle}
          variant="h6"
          color="inherit"
          style={{ flex: 1, cursor: 'pointer' }}
        >
          {props.title}
        </Typography>
        <Typography variant="h6" color="inherit">
          {props.user.label} 様
        </Typography>
        <Button
          variant="text"
          onClick={() => props.onClickLogout('/login')}
          style={{ marginTop: 0 }}
          color="inherit"
        >
          ログアウト
        </Button>
      </Toolbar>
    </AppBar>
  </div>
)

NavBar.defaultProps = { title: 'ロードセル' }

export default NavBar
