import Typography from '@material-ui/core/Typography'
import * as React from 'react'
import { Button, TextField, InputAdornment } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import styled from 'styled-components'

import _ from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import { LogRaw } from '../../types'
import { fetchLogs } from '../../state/Firebase/operations'
import Channel10Graph from './components/Channel10Graph'

type Props = {
  visibles: string[]
  deviceId: string
}

const intiialChannels = {}

_.range(1, 11).forEach(i => {
  intiialChannels[`ch${i.toString().padStart(2, '0')}`] = true
})

const RangeButtons = styled(ButtonGroup)`
  && {
    margin-left: 12px;
  }
`

const rangeGroups: { label: string; range: number }[] = [
  { label: '15分', range: 15 },
  { label: '1時間', range: 60 },
  { label: '1日', range: 60 * 24 },
  { label: '1週間', range: 60 * 24 * 7 },
  { label: '1ヶ月', range: 60 * 24 * 7 * 30 },
  { label: '全て', range: 60 * 24 * 7 * 30 * 12 },
]

const DeviceLogsRecent = (props: Props) => {
  const [range, setRange] = React.useState<number>(15)
  const [preRange, setPreRange] = React.useState<number>(15)
  const [rangeChanged, setRangeChanged] = React.useState<boolean>(false)
  const [logs, setLogs] = React.useState<LogRaw[] | undefined>(undefined)
  const [unit, setUnit] = React.useState<string>('-')

  React.useEffect(() => {
    if (!range) {
      return
    }
    setLogs(undefined)
    let sync = true
    let unwatch: (a?: any) => any = () => {
      //
    }

    fetchLogs(
      props.deviceId,
      +Date.now() - range * 60 * 1000,
      +Date.now(),
      logs => {
        if (sync) setLogs(s => (s || []).concat(logs))
      },
      true,
    ).then(({ unsubscribe, unit }) => {
      unwatch = unsubscribe
      if (sync) setUnit(unit)
    })
    return () => {
      sync = false
      if (unwatch) unwatch()
    }

    // props.fetchRecent(Date.now() - range * 60 * 1000)
    // eslint-disable-next-line
  }, [props.deviceId, range])

  const rangeM = preRange % 60
  const rangeH = Math.floor(preRange / 60)

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div>
          <Typography variant="caption">グラフ範囲設定</Typography>
          <RangeButtons size="small">
            {rangeGroups.map(r => (
              <Button
                key={r.label}
                disabled={r.range === preRange}
                color={r.range === preRange ? 'default' : 'primary'}
                onClick={() => {
                  setPreRange(r.range)
                  setRangeChanged(true)
                }}
              >
                {r.label}
              </Button>
            ))}
          </RangeButtons>
        </div>
        <div>
          <TextField
            value={rangeH}
            type="number"
            style={{ margin: '0px 4px' }}
            onChange={e => {
              setPreRange(Number(e.target.value) * 60 + rangeM)
              setRangeChanged(true)
            }}
            InputProps={{
              style: { width: '120px' },
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment style={{ width: '4em' }} position="end">
                  時間
                </InputAdornment>
              ),
            }}
          />
          <TextField
            value={rangeM}
            type="number"
            style={{ margin: '0px 4px' }}
            onChange={e => {
              setPreRange(rangeH * 60 + Number(e.target.value))
              setRangeChanged(true)
            }}
            InputProps={{
              style: { width: '120px' },
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end">分</InputAdornment>,
            }}
          />
        </div>
        <Button
          disabled={!rangeChanged}
          color="primary"
          style={{ margin: '4px 12px' }}
          variant="contained"
          onClick={() => {
            setRange(preRange)
            setRangeChanged(false)
            // TODO: resynk
          }}
        >
          再読込
        </Button>
      </div>
      {rangeChanged && (
        <Typography variant="caption" color="error">
          再読み込みボタンを押してください
        </Typography>
      )}
      {logs === undefined ? (
        <CircularProgress />
      ) : (
        <Channel10Graph
          deviceId={props.deviceId}
          range={range}
          visibles={props.visibles}
          graphData={logs}
          unit={unit}
          control
        />
      )}
    </>
  )
}

export default DeviceLogsRecent
