import { Button, TextField, Typography } from '@material-ui/core'
import _ from 'lodash'
import * as React from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { numOr } from 'typeor'
import config from '../../../config'
import { downloadCSV } from '../../../state/DeviceById/operations'
import { LogRaw } from '../../../types'
import { timeLabel } from '../../../utils'
import Channel10LineGraphRange, { DomainValue } from './Channel10LineGraphRange'

type Props = {
  deviceId: string
  graphData: LogRaw[]
  visibles: string[]
  control?: boolean
  unit: string
  range?: number
}

function toDomainValue(v: string): ['auto' | number, boolean] {
  if (v === 'auto') {
    return ['auto', true]
  }
  const vn = Number(v)

  return isNaN(vn) ? ['auto', false] : [vn, true]
}

function LogView({
  log,
  visibles,
}: {
  log: LogRaw | null
  visibles: string[]
}) {
  if (!log) {
    return <div style={{ height: '72px' }}>マウスオーバーで表示</div>
  }
  return (
    <div style={{ border: 'solid gray 1px', padding: '8px' }}>
      <Typography>選択中のログ: {timeLabel(log.timestamp)}</Typography>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(10, 1fr)',
          gridGap: '4px',
        }}
      >
        {visibles.map(key => (
          <div key={key}>
            <Typography>{key}</Typography>
            <Typography>{_.ceil(numOr(log.fields[key]), 5)}</Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

const Channel10Graph = (props: Props) => {
  const [selectedLog, setSelectedLog] = React.useState<LogRaw | null>(null)
  const [domainUp, setDomainUp] = useLocalStorage<string>('domainUp', 'auto')
  const [domainDown, setDomainDown] = useLocalStorage<string>(
    'domainDown',
    'auto',
  )
  // const now = Date.now()
  // const xDomain: [DomainValue, DomainValue] = props.range
  //   ? [now - props.range * 60 * 1000, now]
  //   : [`auto`, 'auto']
  const [yDomainUp, domainUpValid] = toDomainValue(domainUp)
  const [yDomainDown, domainDownValid] = toDomainValue(domainDown)
  const logCount = props.graphData.length

  const [yDomain, setYDomain] = React.useState<[DomainValue, DomainValue]>([
    yDomainDown,
    yDomainUp,
  ])

  if (props.graphData.length === 0) {
    return <Typography>表示範囲にログがありません</Typography>
  }
  const changed =
    JSON.stringify(yDomain) === JSON.stringify([yDomainDown, yDomainUp])

  return (
    <div>
      <TextField
        value={domainUp}
        label="上限値"
        error={!domainUpValid}
        helperText="数字 または 'auto'"
        onChange={e => {
          setDomainUp(e.target.value)
        }}
      />
      <TextField
        value={domainDown}
        label="下限値"
        error={!domainDownValid}
        helperText="数字 または 'auto'"
        onChange={e => {
          setDomainDown(e.target.value)
        }}
      />
      <Button
        disabled={changed}
        variant="outlined"
        style={{ margin: '12px 4px' }}
        onClick={() => {
          setYDomain([yDomainDown, yDomainUp])
        }}
      >
        設定
      </Button>
      <Button
        disabled={domainUp === 'auto' && domainDown === 'auto'}
        variant="outlined"
        style={{ margin: '12px 4px' }}
        onClick={() => {
          setDomainUp('auto')
          setDomainDown('auto')
        }}
      >
        自動調整
      </Button>
      <Typography>
        ログ数: {logCount}
        <Button
          variant="outlined"
          style={{ margin: '12px 4px' }}
          onClick={() => {
            downloadCSV(props.deviceId, props.graphData)
          }}
        >
          ダウンロード
        </Button>
      </Typography>
      {config.loadLogCountLimit === logCount && (
        <Typography variant="caption" color="error">
          読み込み上限です
        </Typography>
      )}
      {props.unit && props.unit !== '-' && <p>({props.unit})</p>}
      <Channel10LineGraphRange
        control={props.control}
        yDomain={yDomain}
        graphData={props.graphData}
        unit={props.unit}
        visibles={props.visibles}
        setSelectedLog={setSelectedLog}
      />
      {/* <LogView log={selectedLog} visibles={props.visibles} /> */}
    </div>
  )
}

export default Channel10Graph
