import _ from 'lodash'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { User } from '../../types'
import * as actions from './actions'

export type State = { [id: string]: User }
export const initialState: State = {}

export default reducerWithInitialState<State>(initialState)
  .case(actions.receiveUser, (state, user) => {
    return {
      ...state,
      [user.id]: user,
    }
  })
  .case(actions.removeUser, (state, { id }) => {
    return _.omit(state, [id])
  })
