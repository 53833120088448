import _ from 'lodash'
import { State, User } from '../../types'

export const getUserAll = (state: State) => {
  return _.values(state.UserById)
}

export const getUserIds = (state: State) => {
  return _.keys(state.UserById)
}

export const getUser = (state: State, id: string): User => {
  return state.UserById[id]
}
