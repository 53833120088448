import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Admin } from '../../types'
import * as actions from './actions'

export type State = { [id: string]: Admin }
export const initialState: State = {}

export default reducerWithInitialState<State>(initialState).case(
  actions.receiveAdmin,
  (state, admin) => {
    return {
      ...state,
      [admin.id]: admin,
    }
  },
)
