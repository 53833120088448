import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import * as React from 'react'
import { map } from 'lodash'
import { toggleDeviceVisible } from '../../../state/Firebase/operations'

type Props = {
  deviceIds: string[]
  devicesFilter: { [id: string]: boolean }
}

const DeviceFilter = ({ devicesFilter, deviceIds }: Props) => {
  return (
    <div style={{ display: 'flex' }}>
      <Typography style={{ padding: '10px' }} variant="body1">
        表示するデバイス
      </Typography>
      <div>
        {map(devicesFilter, (v, did) => (
          <FormControlLabel
            key={did}
            control={
              <Checkbox
                checked={v}
                onChange={() => {
                  toggleDeviceVisible(did, !v)
                }}
                value={did}
              />
            }
            label={did}
          />
        ))}
      </div>
    </div>
  )
}

export default DeviceFilter
