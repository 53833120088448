import { Device, ThunkAction, LogRaw } from '../../types'
import { download, makeCSV } from '../../utils'
import { loadLogs } from '../Firebase/operations'
import * as actions from './actions'
import * as selectors from './selectors'

const calcDevice = (device: Device, devicePre?: Device): Device => {
  if (!devicePre) {
    return device
  }

  return {
    ...device,
    lastLogs: device.lastLogs,
  }
}

export function saveDevice(device: Device): ThunkAction {
  return async (dispatch, getState) => {
    const preDevice = selectors.getDevice(getState(), device.id)

    dispatch(actions.receiveDevice(calcDevice(device, preDevice)))
  }
}

export function downloadCSV(deviceId: string, logs: LogRaw[]) {
  download(makeCSV(logs), `${deviceId}`)
}
