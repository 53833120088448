import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import * as history from 'history'
import Helmet from 'react-helmet'
import Init from '../Auth/Init'
import TopPage from '../TopPage'
import LoginPage from '../LoginPage'

import AuthContainer from '../Auth'
import config from '../../config'

export const browserHistory = history.createBrowserHistory()

class App extends React.Component {
  render() {
    return (
      <Router history={browserHistory}>
        <div>
          <Helmet>
            <title>{config.category}</title>
          </Helmet>
          <Init />
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <AuthContainer
              redirectPath="/login"
              userRoute={
                <div>
                  <Switch>
                    <Route exact path={'/'} component={TopPage} />
                    <Route component={() => <Redirect to="/" />} />
                  </Switch>
                </div>
              }
            />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App
