import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { passwordLogin } from '../../state/Firebase/operations'
import { getLoginFieldError } from '../../state/LoginForm/selectors'
import LoginForm from './components/LoginForm'

function LoginFormContainer() {
  const dispatch = useDispatch()
  const error = useSelector(getLoginFieldError)

  return (
    <LoginForm
      onSubmit={({ email, password }) =>
        dispatch(passwordLogin(email, password))
      }
      error={error}
    />
  )
}

export default LoginFormContainer
