import _ from 'lodash'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { LogCount } from '../../types'

import * as actions from './actions'

export type State = { [id: string]: LogCount }

export const initialState: State = {} as State

export default reducerWithInitialState(initialState)
  .case(actions.receiveLogCount, (state, logCount) => {
    return { ...state, [logCount.id]: logCount }
  })
  .case(actions.receiveLogCounts, (state, logCounts) => {
    return { ...state, ..._.keyBy(logCounts, 'id') }
  })
