import { Admin, ThunkAction, User } from '../../types'
import { receiveAdmin } from '../AdminById/actions'
import { receiveUser } from '../UserById/actions'
import { login } from './actions'

export function saveAdminLogin(admin: Admin) {
  return async dispatch => {
    await dispatch(receiveAdmin(admin))
    await dispatch(login({ userId: admin.id, isAdmin: true }))
  }
}
export function saveLogin(user: User): ThunkAction {
  return async dispatch => {
    await dispatch(receiveUser(user))
    await dispatch(login({ userId: user.id, isAdmin: false }))
  }
}
