import { actionCreatorFactory } from 'typescript-fsa'
import { LoginField } from '../../types'
import { State } from './reducer'

const actionCreator = actionCreatorFactory()

export const updateLoginForm = actionCreator<Partial<State>>('updateLoginForm')
export const updateLoginField = actionCreator<Partial<LoginField>>(
  'updateLoginField',
)
export const setLoginErrorMessage = actionCreator<string>(
  'setLoginErrorMessage',
)
