import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Log, LogRaw, Category } from '../types'

export const timeLabel = (timestamp: number) => {
  const m = moment(timestamp)

  return m.format(`YYYY/MM/DD HH:mm:ss (${m.fromNow()})`)
}

export const toLog = (old: LogRaw, id: string = '-'): Log => {
  return Object.assign({}, old, {
    id,
    timeLabel: timeLabel(old.timestamp),
  })
}

const pad2 = (n: number) => `${n}`.padStart(2, '0')
const keys = _.range(1, 11).map(i => `CH${i}`)
const keysField = _.range(1, 11).map(i => `ch${pad2(i)}`)

export function makeCSV(logs: LogRaw[]): string {
  const log = logs[0]
  const head = ['Date', 'Time', ...keys, 'kN']

  if (!log) {
    return head.join(',')
  }
  const toLine = (log: LogRaw) => {
    return [
      ...moment(log.timestamp)
        .format('YYYY-MM-DD_HH:mm:ss')
        .split('_'),
      ...keysField.map(k => log.fields[k]),
      log.fields.power,
    ].map(String)
  }

  const cells = [head, ...logs.map(toLine)]

  return cells.map(vs => vs.join(',')).join('\n')
}

export function download(text: string, name: string) {
  const href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
  const a = document.createElement('a')

  a.setAttribute('href', href)
  a.setAttribute('download', name + '.csv')
  document.body && document.body.appendChild(a)
  a.click()
  a.remove()
}

export const apiClient = axios.create({
  baseURL: 'https://roadcell.planckunits.io',
})

export const categories: { [Id in Category]: string } = {
  zyuutenmieruka: 'ジュウテン',
  roadcell: 'ロードセル',
  zentyou: 'ゼンチョウ',
  // eslint-disable-next-line @typescript-eslint/camelcase
  roadcell_zentyou: 'ロードセル・ゼンチョウ',
}
