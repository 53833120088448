import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Clock } from '../../types'

import * as actions from './actions'

export type State = Clock

const initialState: State = {
  timestamp: 0,
  timestampStr: '---',
}

export default reducerWithInitialState(initialState).case(
  actions.updateClock,
  (state, Clock) => {
    return { ...state, ...Clock }
  },
)
