import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Session } from '../../types'
import * as actions from './actions'

export type State = { [id: string]: Session }
export const initialState: State = {}

export default reducerWithInitialState<State>(initialState).case(
  actions.receiveSession,
  (state, session) => {
    return {
      ...state,
      [session.id]: session,
    }
  },
)
