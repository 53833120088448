import * as React from 'react'
import { Chart } from 'react-google-charts'

import _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import { LogRaw } from '../../../types'

const timeFormat = 'YYYY/MM/DD HH:mm:ss'

type Props = {
  graphData: LogRaw[]
  visibles: string[]
  control?: boolean
  yDomain: [DomainValue, DomainValue]
  unit: string
  setSelectedLog: (log: LogRaw | null) => void
}
export type DomainValue = number | 'auto'
function Channel10LineGraphRange(props: Props) {
  const firstLog = _.first(props.graphData)
  const lastLog = _.last(props.graphData)

  const logs = React.useMemo(() => {
    const chs = props.visibles
    const logs = [
      ['time', ...chs],
      ...props.graphData.map(v => [
        new Date(v.timestamp),
        ...chs.map(k => v.fields[k]),
      ]),
    ]

    return logs
    // eslint-disable-next-line
  }, [
    firstLog && firstLog?.timestamp,
    lastLog && lastLog.timestamp,
    JSON.stringify(props.visibles),
  ])

  if (!firstLog || !lastLog) {
    return null
  }
  // 最終500 件の範囲表示
  const visibleRangeFirst =
    props.graphData.length <= 500
      ? firstLog.timestamp
      : props.graphData[props.graphData.length - 500].timestamp

  const options = {
    chartArea: {
      height: '75%',
      width: '80%',
    },
    hAxis: {
      // maxValue: 200,
      // gridlines: {
      //   units: {
      //     days: { format: ['MM/dd'] },
      //     hours: { format: ['HH:mm', 'ha'] },
      //   },
      // },
      // minorGridlines: {
      //   units: {
      //     days: { format: ['MM/dd'] },
      //     hours: { format: ['HH:mm'] },
      //     minutes: { format: ['HH:mm', ':mm'] },
      //   },
      // },
    },
    vAxis: {
      // format: '0',
      // viewWindowMode: 'explicit',
      viewWindow: {
        min: props.yDomain[0],
        max: props.yDomain[1],
      },
    },
  }

  console.log(props.graphData.length)
  return (
    <Style>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{moment(firstLog.timestamp).format(timeFormat)}</span>
        <span>{moment(lastLog.timestamp).format(timeFormat)}</span>
      </div>
      <div className="chart-main">
        <Chart
          width={'100%'}
          height={500}
          chartLanguage="ja"
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={logs}
          chartEvents={[
            {
              eventName: 'ready',
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart()

                google.visualization.events.addListener(
                  // @ts-ignore
                  chart,
                  'onmouseover',
                  ({ row }: { row?: number; column?: number }) => {
                    if (!row) return
                    // props.setSelectedLog(props.graphData[row])
                  },
                )
              },
            },
          ]}
          options={options}
          chartPackages={['corechart', 'controls']}
          controls={[
            {
              controlType: 'ChartRangeFilter',
              options: {
                filterColumnIndex: 0,
                ui: {
                  chartType: 'LineChart',
                  chartOptions: {
                    chartArea: { width: '90%', height: '50%' },
                    // hAxis: { baselineColor: 'none' },
                  },
                },
              },
              controlPosition: 'bottom',
              controlWrapperParams: {
                state: {
                  range: {
                    start: new Date(visibleRangeFirst),
                    end: new Date(lastLog.timestamp),
                  },
                },
              },
            },
          ]}
        />
      </div>
      <div className="side-ts">
        <span>{moment(firstLog.timestamp).format(timeFormat)}</span>
        <span>{moment(lastLog.timestamp).format(timeFormat)}</span>
      </div>
    </Style>
  )
}

const Style = styled.div`
  > div.chart-main {
    height: 610px;
    /* overflow: hidden; */
  }
  .side-ts {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -144px;

    span {
      z-index: 100;
    }
  }
`

export default Channel10LineGraphRange
