import { Button, Paper } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSessionStorage } from 'react-use'
import { useBreakpoints } from 'react-use-breakpoints'
import { getLoginUserTitle } from '../../state/Auth/selectors'
import {
  getVisibleDeviceIds,
  getVisibleDeviceMap,
} from '../../state/DeviceById/selectors'
import { initialFirebaseLoad } from '../../state/Firebase/operations'
import NavBar from '../NavBarContainer'
import DeviceFilter from './components/DeviceFilter'
import RoadcellDevice from './RoadcellDevice'

type ColNum = 1 | 2 | 4
const colNums: ColNum[] = [1, 2]

function TopPage() {
  const dispatch = useDispatch()
  const visibles = useSelector(getVisibleDeviceMap)
  const deviceIds = useSelector(getVisibleDeviceIds)
  const title = useSelector(getLoginUserTitle)

  React.useEffect(() => {
    dispatch(initialFirebaseLoad())
    // eslint-disable-next-line
  }, [])

  const [colNum, setColNum] = useSessionStorage<ColNum>('col_num', 1)

  const { breakpoint } = useBreakpoints()

  const isMobile = breakpoint === 'xs'

  React.useEffect(() => {
    console.log(breakpoint)

    if (isMobile) {
      setColNum(1)
    }
  }, [isMobile])

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const cw: ColNum = 12 / colNum

  return (
    <div>
      <NavBar />
      <Grid container justify={'center'}>
        <Grid item xs={12} sm={12} md={12}>
          <div
            style={{
              padding: '12px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Typography variant={'h5'}>{title}</Typography>
            </div>
            <div></div>
          </div>
          <Paper style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {colNums.map(i => (
                <Button
                  key={`col${i}`}
                  variant={'contained'}
                  color={colNum === i ? 'primary' : 'default'}
                  onClick={() => setColNum(i)}
                >
                  {i}列
                </Button>
              ))}
            </div>
            <DeviceFilter deviceIds={deviceIds} devicesFilter={visibles} />
            <Grid container justify="space-between">
              {deviceIds.map(deviceId => (
                <Grid key={deviceId + `_${colNum}`} item xs={12} sm={cw}>
                  <RoadcellDevice deviceId={deviceId} col={colNum} />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default TopPage
