import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { FieldError, LoginField } from '../../types'
import * as actions from './actions'

export type State = {
  field: LoginField
  error: FieldError
}

const initialState: State = {
  field: {
    email: '',
    password: '',
  },
  error: { hasError: false },
}

export default reducerWithInitialState(initialState)
  .case(actions.updateLoginForm, (state, payload) => {
    return { ...state, ...payload }
  })
  .case(actions.setLoginErrorMessage, (state, message) => {
    return { ...state, error: { hasError: true, message } }
  })
  .case(actions.updateLoginField, (state, fields) => {
    return {
      ...state,
      field: {
        ...state.field,
        ...fields,
      },
    }
  })
