import { connect } from 'react-redux'
import { getAuth, getLoginUserAdmin } from '../../state/Auth/selectors'
import { logout } from '../../state/Firebase/operations'
import { Admin, Auth, User } from '../../types'
import { State } from '../../types/state'
import { moveTopPage } from '../App/operations'

import config from '../../config'
import NavBar from './components/NavBar'

type SProps = {
  auth: Auth
  user: User | Admin
}

type OProps = {
  title?: string
}
type DProps = {
  onClickTitle: () => void
  onClickLogout: () => void
}

const conn = connect<SProps, DProps, OProps, State>(
  state => ({
    auth: getAuth(state),
    user: getLoginUserAdmin(state),
    title: config.category,
  }),
  {
    onClickTitle: moveTopPage,
    onClickLogout: logout,
  },
)

export default conn(NavBar)
